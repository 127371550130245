import React from 'react';
import * as S from './FollowUpBox.style';
import { useTranslation } from 'react-i18next';
import * as P from '../../../../../../../utils/pipes';

function FollowUpBox({ userToProcess, currentStage }) {
  const {
    t,
    i18n: { language },
  } = useTranslation('global');

  const frequency = () => {
    if (userToProcess.processStage.frequencyType === 'days') {
      return `${
        userToProcess.processStage.frequency > 1
          ? userToProcess.processStage.frequency
          : ''
      }
        ${
          userToProcess.processStage.frequency > 1
            ? t('globally.days')
            : t('globally.day')
        }`;
    } else if (userToProcess.processStage.frequencyType === 'months') {
      return `${
        userToProcess.processStage.frequency > 1
          ? userToProcess.processStage.frequency
          : ''
      }
        ${
          userToProcess.processStage.frequency > 1
            ? t('globally.months')
            : t('globally.month')
        }`;
    } else {
      return `${userToProcess.processStage.frequency}`;
    }
  };

  const daysRemaining = () => {
    const date = new Date(userToProcess.nextUpdate);
    const dateNow = new Date(Date.now());
    const daysRemaining =
      Math.floor((date - dateNow) / (1000 * 60 * 60 * 24)) + 1;
    return Math.abs(daysRemaining);
  };

  return (
    <S.FollowUpBox
      color={currentStage.color}
      backgroundColor={currentStage.backgroundColor}
      hoverColor={currentStage.hoverColor}
    >
      <div className='left-content'>
        <div className='img-container'>
          <img
            src={userToProcess.user.picture}
            alt=''
            className='patient-img'
          />
        </div>

        <div className='patient-info'>
          <div className='patient-info__name'>
            {userToProcess.user.name + ' ' + userToProcess.user.lastname}
          </div>

          <div className='patient-info__frecuency'>
            {t('followUp.followUpFrequency')}
            <b>
              {t('globally.each')} {frequency()}
            </b>
          </div>
        </div>
      </div>

      <div className='center-content'>
        <div className='program-info'>
          <div className='program-info__label'>Program</div>

          <div className='program-info__value'>
            {P.dataTranslation(userToProcess.process?.processName, language)}
          </div>

          <div className='program-info__label'>Status program</div>

          <div className='program-info__value'>
            {/* {t("followUp.stage")} */}
            {P.dataTranslation(userToProcess.processStage.stageName, language)}
          </div>
        </div>
      </div>

      <div className='right-content'>
        <div className='dates-box'>
          <div className='date-container date-container--initial-date'>
            <i className='date-container__icon icon zaia-i-time'></i>

            <div className='date-container__info'>
              <div className='date-container__info-state'>
                {currentStage.key === 'today' && (
                  <b>{t('globally.today').toUpperCase()}</b>
                )}

                {currentStage.key === 'next' && (
                  <div className='date-container__info-state-days-time'>
                    {daysRemaining()}{' '}
                    {daysRemaining() < 2
                      ? t('followUp.dayLeft')
                      : t('followUp.daysLeft')}
                  </div>
                )}

                {currentStage.key === 'due' && (
                  <>
                    <b>{t('followUp.due').toUpperCase()}</b>
                    <div className='date-container__info-state-days-time'>
                      - {daysRemaining()}{' '}
                      {daysRemaining() < 2
                        ? t('followUp.dayLate')
                        : t('followUp.daysLate')}
                    </div>
                  </>
                )}
              </div>

              <div className='date-container__info-date'>
                {P.longDate(userToProcess.nextUpdate, t, language)}
              </div>

              <div className='date-container__info-final-date'>
                {t('followUp.lastFollowUp')}
                <span className='date-container__info-final-date--days'>
                  {P.shortDate(
                    userToProcess.userToProcessHistories[0]?.created_at,
                    t,
                    language
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </S.FollowUpBox>
  );
}

export default FollowUpBox;
