import React, { useEffect, useRef, useState } from "react";
import * as S from "./TransducerView.style";
import { useTranslation } from "react-i18next";

// Components
import Transducer from "./components/transducer";
import TransductionActions from "./components/transductionActions";
import Modal from "../../../utils/modal";
import ZaiaModalResponseContent from "../../zaiaComponents/zaiaModalResponseContent";

// assets
const defaultUser = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user.png`;
const temporal = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-mail.svg`;

function TransducerView({
  closeFunc,
  maxHeight,
  maxViewActived,
  setMaxViewActived,
  minimizeActived,
  setMinimizeActived,
  changeView
}) {    
  const {t, i18n: { language }} = useTranslation("global");
  const [transducerState, setTransducerState] = useState(null);
  const [transducerType, setTransducerType] = useState(null);
  const [manageTransductionActived, setManageTransductionActive] = useState(false);
  const [manageTransductionActivedConfirmation, setManageTransductionActivedConfirmation] = useState(false);
  const [closeConfirmation, setCloseConfirmation] = useState(false);
  const [recordingActived, setRecordingActived] = useState(false);

  const transducerTypes = [
    {
      id: 1,
      name: t("transductor.medicalConsultation"),
      description: t("transductor.medicalConsultationDescription")
    },
    {
      id: 2,
      name: t("transductor.advancedNoteTaking"),
      description: t("transductor.advancedNoteTakingDescription")
    }
  ] 	


  if(minimizeActived) {
    return (
      <S.TransducerView>
        <div className="transducer-minimized">
          <div className="transducer-minimized__top">
            <div className="transducer-minimized__buttons">
              <button className="circle-button circle-button--close-notes"
                onClick={() => closeFunc()}
              >
                <i className="circle-button__icon icon zaia-icono-cerrar"></i>
              </button>
              <button className="circle-button circle-button--maximize-notes"
                onClick={() => setMinimizeActived(false)}
              >
                <i className="circle-button__icon icon zaia-i-up"></i>
              </button>
            </div>
            <div className="transducer-minimized__text">
              <div className="title">
                {t("transductor.sessionInProgress")}
              </div>
              <div className="subtitle">
                {t("transductor.medicalConsultation")}
              </div>
            </div>
            <div className="transducer-minimized__state">
              <div className="transducer-state">
                {
                  transducerState &&
                  <div className="transducer-state__circle"></div>
                }
                {
                  transducerState === false &&
                  <i className="transducer-state__icon icon zaia-icono-flechas-arriba-abajo ~changeIcon"></i>
                }
                <div className="transducer-state__text">
                  {
                    transducerState ? `${t("transductor.listening")}...` : t("transductor.paused")
                  }
                </div>
              </div>
            </div>
          </div>
          {
            manageTransductionActived &&
            <div className="transducer-minimized__bottom">
              {
                transducerState &&
                <div className="button-record">
                  <div className="button-record__countinue"></div>
                  <div className="button-record__countinue-circle"></div>
                </div>
              }
              {
                transducerState === false &&
                <div className="button-paused">
                  <i className="button-paused__icon icon zaia-i-medical-chart ~changeIcon"></i>
                </div>
              }
              <div className="button-finalize"> 
                {t("transductor.finalizeSession")}
              </div>
            </div>
          }
        </div>
      </S.TransducerView>
    )
  }

  return (
    <S.TransducerView
      maxViewActived={maxViewActived}
      recordingActived={recordingActived}
    >
      <Modal
        open={manageTransductionActivedConfirmation}
      >
        <S.ModalResponse>
          <div className="modal-background" onClick={() => setManageTransductionActivedConfirmation(false)}>
          </div>
          <div className="modal-container">
              <ZaiaModalResponseContent
                button1={() => {
                  setManageTransductionActive(true);
                  setManageTransductionActivedConfirmation(false);
                  setMaxViewActived(true);
                  setRecordingActived(false);
                }}
                button2={() => setManageTransductionActivedConfirmation(false)}
                doubleButton={true}
                formSuccess={false}
                otherImg={temporal}
                button1Text={t("globally.yes")}
                button2Text={t("globally.no")}
                title={t("transductor.finalizeSessionConfirmation")}
                subtitle={t("transductor.finalizeSessionConfirmationSub")}
              />
          </div>
        </S.ModalResponse>
      </Modal>
      <Modal
        open={closeConfirmation}
      >
        <S.ModalResponse>
          <div className="modal-background" onClick={() => setCloseConfirmation(false)}>
          </div>
          <div className="modal-container">
              <ZaiaModalResponseContent
                button1={() => {
                  closeFunc();
                  setCloseConfirmation(false);
                }}
                button2={() => setCloseConfirmation(false)}
                doubleButton={true}
                formSuccess={false}
                button1Text={t("followUp.confirm")}
                button2Text={t("globally.cancel")}
                title={t("transductor.closeSessionConfirmation")}
                subtitle={t("transductor.closeSessionConfirmationSub")}
              />
          </div>
        </S.ModalResponse>
      </Modal>
      <div className="transductor">
        <Header 
          maxViewActived={maxViewActived}
          setMaxViewActived={setMaxViewActived}
          minimizeActived={minimizeActived}
          setMinimizeActived={setMinimizeActived}
          transducerState={transducerState}
          transducerType={transducerType}
          manageTransductionActived={manageTransductionActived}
          recordingActived={recordingActived}
          setRecordingActived={setRecordingActived}
          transducerTypes={transducerTypes}
          setCloseConfirmation={setCloseConfirmation}
          changeView={changeView}
        />
        <div className="transductor-container">
          <TransductorContent 
            maxViewActived={maxViewActived}
            manageTransductionActived={manageTransductionActived}
            transducerTypes={transducerTypes}
            transducerType={transducerType}
            setTransducerType={setTransducerType}
            recordingActived={recordingActived}
            setRecordingActived={setRecordingActived}
            transducerState={transducerState}
            setTransducerState={setTransducerState}
            setManageTransductionActiveConfirmation={setManageTransductionActivedConfirmation}
          />
        </div>
      </div>
    </S.TransducerView>
  );
}

const Header = ({ 
  maxViewActived,
  setMaxViewActived,
  setMinimizeActived,
  transducerState,
  transducerType,
  manageTransductionActived,
  recordingActived,
  setRecordingActived,
  transducerTypes,
  setCloseConfirmation,
  changeView
}) => {

  const {t, i18n: { language }} = useTranslation("global");

  return (
    <S.HeaderDiv
      maxViewActived={maxViewActived}
    >
      <div className="header">
        <div className="header__top">
          <div className="header__left">
            <div className="header__close-chat" onClick={() => setCloseConfirmation(true)}>
              <i className="header__close-chat-icon icon zaia-icono-cerrar" />
            </div>
            {
              !manageTransductionActived && 
              <div className="header__max" onClick={() => setMaxViewActived(!maxViewActived)}>
                {
                  maxViewActived
                  ? <i className="header__max-icon icon zaia-icono-flechas-arriba-abajo ~changeicon" />
                  : <i className="header__max-icon icon zaia-icono-flechas-arriba-abajo ~changeicon" />
                }
              </div>
            }
            {
              <div className="header__reset-button" onClick={() => changeView(0)}>{t("chatbotAI.reset")}</div>
            }
            {
              recordingActived &&
              <div className="header__minimize"
                onClick={() => setMinimizeActived(true)}
              >
                <i className="header__minimize-icon icon zaia-icono-dropdown-light" />
                <div className="header__minimize-text">{t("globally.minimize")}</div>
              </div>
            }
          </div>
          {
            manageTransductionActived &&
            <div className="header__center">
              {t("transductor.manageTransduction")}...
            </div>
          }
          <div className="header__right">
            {
              manageTransductionActived &&
              <div className="header__type-transductor">
                {t("transductor.transductorMode")} <b>
                  {
                    transducerTypes.find(type => type.id === transducerType)?.name
                  }
                </b>
              </div>
            }
            {
              !manageTransductionActived &&
              <div className="header__title">
                <i className="header__title-icon icon zaia-document"></i>
                <div className="header__title-text">{
                  t("globally.transductor")
                }</div>
              </div>
            }
          </div>
        </div>
        { 
          transducerType && recordingActived && !manageTransductionActived &&
          <div className="header__bottom">
            <div className="header__left">
              <div className="transductor-type">
                {t("transductor.transductorMode")} <b>
                  {
                    transducerTypes.find(type => type.id === transducerType)?.name
                  }
                </b>
              </div>
            </div>
            <div className="header__right">
              <div className="transductor-state">
                {
                  transducerState &&
                  <div className="transductor-state__circle"></div>
                }
                {
                  transducerState === false &&
                  <i className="transductor-state__icon icon zaia-icono-flechas-arriba-abajo ~changeIcon"></i>
                }
                <div className="transductor-state__text">
                  {
                    transducerState ? `${t("transductor.listening")}...` : t("transductor.paused")
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </S.HeaderDiv>
  );
};

const TransductorContent = ({
  manageTransductionActived,
  transducerTypes,
  transducerType,
  setTransducerType,
  recordingActived,
  setRecordingActived,
  transducerState,
  setTransducerState,
  setManageTransductionActiveConfirmation
}) => {

  const {t, i18n: { language }} = useTranslation("global");
  const [messages, setMessages] = useState([]);
  const [transcription, setTranscription] = useState("");

  if(!manageTransductionActived) {
    return <Transducer
      transducerTypes={transducerTypes}
      transducerType={transducerType}
      setTransducerType={setTransducerType}
      recordingActived={recordingActived}
      setRecordingActived={setRecordingActived}
      transducerState={transducerState}
      setTransducerState={setTransducerState}
      setManageTransductionActiveConfirmation={setManageTransductionActiveConfirmation}
      messages={messages}
      setMessages={setMessages}
      transcription={transcription}
      setTranscription={setTranscription}
    />
  } else {
    return <TransductionActions
      messages={messages}
      setMessages={setMessages}
      transducerType={transducerType}
      transcription={transcription}
      setTranscription={setTranscription}
    />
  }
}

export default TransducerView;
