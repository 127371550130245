import { gql } from "@apollo/client";

export const QUERY_GET_PROCESS_NAMES = gql`
  query QUERY_GET_PROCESS_NAMES(
    $whereFilter: process_bool_exp
  ) {
    process(where: $whereFilter) {
      id
      processName
      descript
      icon
      nameKey
      triggerText
      processToCustomForms(order_by: {customFormId: asc}) {
        customFormId
        id
        processId
      }
      userToProcesses {
        id
        userId
        isAcepted
        process {
          processToCustomForms(order_by: {customFormId: asc}) {
            customFormId
            id
            processId
          }
        }
      }
    }
  }
`;

export const QUERY_GET_PROCESS_BY_ID = gql`
  query QUERY_GET_PROCESS_NAMES(
    $processId: Int
  ) {
    process(where: {id: {_eq: $processId}}) {
      id
      processName
      descript
      icon
      nameKey
      triggerText
      processToCustomForms(order_by: {customFormId: asc}) {
        customFormId
        id
        processId
      }
      userToProcesses {
        id
        userId
        isAcepted
        process {
          processToCustomForms(order_by: {customFormId: asc}) {
            customFormId
            id
            processId
          }
        }
      }
    }
  }
`;


export const QUERY_GET_PROCESS_TYPES_BY_ID = gql`
  query QUERY_GET_PROCESS_TYPES (
    $processId: [Int!]
    $technology: String!
  ) {
    process(where: {id: {_in: $processId}}) {
      id
      processName
      processToSideEffects {
        id
        typeSideEffect
        severity
      }
      processToVitalSigns {
        id
        max
        min
        vitalSign
        severity
      }
      vector_context_collections(where: {embedding_technology: {_eq: $technology}}) {
        collection_name
        group_name
        embedding_model
        embedding_model_vector_dimension
        medical_process_id
        embedding_technology
        vector_context_collection_partitions {
          partition_name
          document_name
          is_assistant
          id
          vector_context_collection {
            collection_name
            embedding_model
            embedding_model_vector_dimension
            medical_process_id    
            embedding_technology
          }
        }
      }
    }
  }
`;

export const QUERY_GET_PROCESS_TYPES_BY_IDS = gql`
  query QUERY_GET_PROCESS_TYPES_BY_IDS (
    $processId: [Int!]
  ) {
    process(where: {id: {_in: $processId}}) {
      id
      processName
      nameKey
      processToSideEffects {
        id
        typeSideEffect
        severity
      }
      processToVitalSigns {
        id
        max
        min
        vitalSign
        severity
      }
    }
  }
`;

export const QUERY_GET_PROCESS_TYPES = gql`
  query QUERY_GET_PROCESS_TYPES {
    process {
      id
      processName
      nameKey
      templates {
        content
        created_at
        doctorId
        healthSystemId
        id
        processId
        title
        updated_at
      }
      processToSideEffects {
        id
        typeSideEffect
        severity
      }
      processToVitalSigns {
        id
        max
        min
        vitalSign
        severity
      }
      userToProcesses( where: {isAcepted: {_eq: true}}) {
        id
        userId
        isAcepted
        stageId
      }
      processStages {
        id
        descript
        frequency
        frequencyType
        stageName
      }
    }
  }
`;

export const QUERY_GET_PROCESS_ROLES_BY_ID = gql`
  query QUERY_GET_PROCESS_ROLES_BY_ID(
    $processId: Int!,
    $roleId: String
  ) {
    processRole(where: {processId: {_eq: $processId}, id: {_eq: $roleId}}) {
      id
      processId
      roleName
    }
  }
`

export const QUERY_GET_TEMPLATES_BY_PROCESS = gql`
  query QUERY_GET_TEMPLATES_BY_PROCESS($processId: Int) {
    template(where: {processId: {_eq: $processId}}) {
      content
      created_at
      doctorId
      healthSystemId
      id
      processId
      title
      updated_at
    }
  }
`