import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./Transducer.style";
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";
import Modal from "../../../../../utils/modal";
import ModalSelectPatient from "../../../shared/modals/modalSelectPatient";
import ZaiaSelect from "../../../../zaiaComponents/zaiaSelect";
import * as P from "../../../../../utils/pipes";
import TranducerMessagesList from "../tranducerMessagesList";
import useInfoToken from "../../../../../hooks/useInfoToken";

const defaultUser = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user.png`;
const feather = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/magnifying-glass.svg`;

const TRANSDUCTOR_MD_ENDPOINT = process.env.REACT_APP_TRANSDUCTOR_MD_ENDPOINT;
const TRANSDUCTOR_MC_ENDPOINT = process.env.REACT_APP_TRANSDUCTOR_MC_ENDPOINT;

function Transducer({
  transducerTypes,
  transducerType,
  setTransducerType,
  recordingActived,
  setRecordingActived,
  transducerState,
  setTransducerState,
  setManageTransductionActiveConfirmation,
  messages,
  setMessages,
  transcription,
  setTranscription
}) {

  const { t, 
    i18n: { language }
  } = useTranslation("global"); 

  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [model, setModel] = useState("latest_long");
  const { token } = useInfoToken();
  
  const audioContextRef = useRef(null);
  const mediaStreamRef = useRef(null);
  const socketRef = useRef(null);

  const wsUrlNoteTaking = `${TRANSDUCTOR_MD_ENDPOINT}/?language=${P.languageSplited(language) === "es" ? "es-ES" : "en-US"}&model=${model}&token=${token}`;
  const wsUrlMedicalConsultation = `${TRANSDUCTOR_MC_ENDPOINT}/?language=${P.languageSplited(language) === "es" ? "es-ES" : "en-US"}&model=${model}&token=${token}`;


  // use effect with function that finish process whe the component is unmounted
  useEffect(() => {
    return () => {
      stopRecordingMedicalConsultation();
      stopRecordingNoteTaking();
    };
  }, []);

  const startRecordingMedicalConsultation = async () => {

    try {
      setError("");
      setIsRecording(true);

      socketRef.current = new WebSocket(wsUrlMedicalConsultation);

      socketRef.current.onopen = () => {
        setStatus("Conectado y grabando");
      };

      socketRef.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        addMessage(message.speakerTag, message.transcript);
      };

      socketRef.current.onerror = (err) => {
        setError("Error de WebSocket: " + err.message);
      };

      socketRef.current.onclose = () => {
        setStatus("Desconectado");
        setIsRecording(false);
      };

      audioContextRef.current = new AudioContext({ sampleRate: 16000 });
      mediaStreamRef.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      const source = audioContextRef.current.createMediaStreamSource(
        mediaStreamRef.current
      );

      await audioContextRef.current.audioWorklet.addModule("audio-processor.js");

      const audioWorkletNode = new AudioWorkletNode(
        audioContextRef.current,
        "audio-processor"
      );

      audioWorkletNode.port.onmessage = (event) => {
        if (socketRef.current?.readyState === WebSocket.OPEN) {
          socketRef.current.send(event.data);
        }
      };

      source.connect(audioWorkletNode);
      audioWorkletNode.connect(audioContextRef.current.destination);
    } catch (err) {
      setError("Error: " + err.message);
      setIsRecording(false);
    }
  };

  const stopRecordingMedicalConsultation = () => {
    if (socketRef.current) socketRef.current.close();
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
    }
    if (audioContextRef.current) audioContextRef.current.close();

    setIsRecording(false);
  };

  const addMessage = (speakerTag, text) => {
    setMessages((prev) => [...prev, { 
      messagerType: speakerTag === 1, 
      messageText: text 
    }]);
  };

  const startRecordingNoteTaking= async () => {
    try {
      // Crear la conexión WebSocket
      const socket = new WebSocket(wsUrlNoteTaking);
      console.log("asdfsadf", socket);
      socketRef.current = socket;

      socket.onopen = () => {
        setStatus("Conectado y grabando");
        setIsRecording(true);
      };

      socket.onmessage = (event) => {
        console.log("asdfsadf", event);
        
        const message = JSON.parse(event.data);
        setTranscription((prev) => prev + message.transcription + " ");
      };

      socket.onerror = (err) => {
        setError(`Error de WebSocket: ${err.message}`);
      };

      socket.onclose = (closeEvent) => {
        if (closeEvent.code === 1008) {
          console.error(`WebSocket Error: ${closeEvent.reason}`);
        }
        setStatus("Desconectado");
        setIsRecording(false);
      };

      // Crear AudioContext con tasa de muestreo de 16 kHz
      const audioContext = new AudioContext({ sampleRate: 16000 });
      audioContextRef.current = audioContext;

      // Obtener el stream de audio del micrófono
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      mediaStreamRef.current = mediaStream;

      // Crear MediaStreamAudioSourceNode del stream de audio
      const source = audioContext.createMediaStreamSource(mediaStream);

      // Cargar el AudioWorklet
      await audioContext.audioWorklet.addModule("audio-processor.js");

      // Crear AudioWorkletNode
      const audioWorkletNode = new AudioWorkletNode(
        audioContext,
        "audio-processor"
      );

      // Manejar mensajes desde el AudioWorkletProcessor
      audioWorkletNode.port.onmessage = (event) => {
        if (socket.readyState === WebSocket.OPEN) {
          socket.send(event.data); // Enviar datos de audio al WebSocket
        }
      };

      // Conectar los nodos del audio
      source.connect(audioWorkletNode);
      audioWorkletNode.connect(audioContext.destination);

      setStatus("Grabación iniciada");
    } catch (err) {
      setError(`Error: ${err.message}`);
    }
  };

  const stopRecordingNoteTaking = () => {
    if (socketRef.current) {
      socketRef.current.close(); // Cerrar WebSocket
    }
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop()); // Detener los medios
    }
    if (audioContextRef.current) {
      audioContextRef.current.close(); // Cerrar el AudioContext
    }

    setIsRecording(false);
    setStatus("Grabación detenida");
    // setTranscription(""); // Limpiar transcripciones
  };

  const finilizeSession = () => {
    setManageTransductionActiveConfirmation(true);
    setTransducerState(false);
    if(transducerType === 2) {
      stopRecordingNoteTaking();
    } else {
      stopRecordingMedicalConsultation();
    }
  }

  return (
    <S.Transducer>
      <div className="transducer-content">
        {
          recordingActived &&
          <TranducerMessagesList
            transducerState={transducerState}
            transducerType={transducerType}
            transcription={transcription}
            messages={messages}
          />
        }
        {
          !recordingActived &&
          <TransductorChooseType
            transducerTypes={transducerTypes}
            transducerType={transducerType}
            setTransducerType={setTransducerType}
          />
        }
      </div>
      {/* <p id="status" style={{ color: isRecording ? "green" : "red" }}>
        {status}
      </p> */}
      <div className="space-start-recording">
        {
          transducerState === null &&  
          <button className="recording-button"
            onClick={() => {
              if(transducerType) {
                setRecordingActived(!recordingActived)
                setTransducerState(true);
                if(transducerType === 2) {
                  startRecordingNoteTaking();
                } else {
                  startRecordingMedicalConsultation();
                }
              }
            }}
          >
            <div className="recording-button__text">{t("transductor.startSession")}</div>
            {
              transducerType 
              ? <div className="recording-button__circle"></div>
              : <i className="recording-button__icon icon zaia-i-lock-chat"></i>
            }
          </button>
        }
        {
          (transducerState === true || transducerState === false) &&
          <div className="active-recording-buttons">
            {
              transducerState === true &&
              <button className="recording-button recording-button--pause"
                onClick={() => {
                  setTransducerState(false);
                  if(transducerType === 2) {
                    stopRecordingNoteTaking();
                  } else {
                    stopRecordingMedicalConsultation();
                  }
                }}
              > 
                <i className="recording-button__icon recording-button__icon--pause icon zaia-icono-swipe-bars"></i>
              </button>
            }
            {
              transducerState === false &&
              <button className="recording-button recording-button--continue"
                onClick={() => {
                  setTransducerState(true);
                  if(transducerType === 2) {
                    startRecordingNoteTaking();
                  } else {
                    startRecordingMedicalConsultation();
                  }
                }}
              > 
                <div className="recording-button__text">{t("globally.continue")}</div>
                <div className="recording-button__circle"></div>
              </button>
            }
            <button className="recording-button recording-button--finish"
              onClick={() => finilizeSession()}
            >
              {t("transductor.finalizeSession")}
            </button>
          </div>
        }
      </div>
    </S.Transducer>
  );
}

function TransductorChooseType({
  transducerType,
  setTransducerType,
  transducerTypes
}) {

  const { t } = useTranslation("global");

  return (
    <div className="transducer-choose-type">
      <div className="transductor-mode">
        <div className="transductor-mode__label">{t("transductor.transductorMode")}</div>
        <div className="transductor-mode__selected">
          <ZaiaSelect
            type="select"
            setValue={(e) => {
                setTransducerType(e)
              }
            }
            value={transducerType}
            items={transducerTypes.map(
              (type) => {
                return {
                  value: type.id,
                  text: type.name
                }
              }
            )}
            placeholder={t("transductor.noMode")}
            placeholderTextColor={"#3E3DA3"}
            shadow={false}
            fontSize="20px"
            backgroundColor={"#FFFFFF"}
            borderRadius={"20px"}
            heightList={"200px"}
            padding={"20px 15px"}
            textColor={"#59599E"}
            arrowColor={"#59599E"}
            borderColor={"#6C6BCC"}
            textItemsColor={"#3E3DA3"}
          />
        </div>
      </div>
      <div className="message-space">
        {
          transducerType === 1 &&
          <img src={feather} alt="asdg" className="message-space__img--note-taking" />
        }
        {
          transducerType === 2 &&
          <img src={feather} alt="asdf" className="message-space__img--medical-consultation" />
        }
        {
          !transducerType &&
          <img src={feather} alt="asdf" className="message-space__img--start" />
        }
        <div className="message-space__text">
          {
            !transducerType
            ? t("transductor.selectAMode")
            : transducerTypes.find(type => type.id === transducerType)?.description
          }
        </div>
      </div>
    </div>
  );
}


export default Transducer;
